import React from 'react';
import { render } from 'react-dom';
import 'stylesheets/main.css';
import 'stylesheets/v2/layout';
import optionEquivalent from 'optionEquivalent';
import DonateForm from 'components/DonateForm';
import getMeta from 'utils/getMeta';
import '@fortawesome/fontawesome-free/css/all.css';

function togglePaymentChannel() {
    var tabs = document.getElementsByClassName("js-tabs");
    [...tabs].forEach((t) => {
        t.addEventListener("click", function (e) {
            e.preventDefault();
            var activeTabs = document.querySelectorAll(".js-tabs.active");

            [...activeTabs].forEach((at) => {
                at.classList.remove("active");
                $(`div.${at.id}-tab`).removeClass("active");
            })
            t.classList.toggle("active");
            $(`div.${t.id}-tab`).addClass("active");
        });
    })
}

function selectDonationPage() {
    var tabs = document.querySelectorAll(".js-donation-page input");
    [...tabs].forEach((t) => {
        t.addEventListener("change", function (e) {
            e.preventDefault();
            var activeRadioBtn = document.querySelectorAll(".js-donation-page.selected");

            [...activeRadioBtn].forEach((at) => {
                at.classList.remove("selected");
            })

            if (t.checked) {
                t.parentElement.classList.add('selected');
            } else {
                t.parentElement.classList.remove('selected');
            }
        });
    })
}

document.addEventListener("DOMContentLoaded", () => {
    togglePaymentChannel();
    selectDonationPage();
});